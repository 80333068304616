

.name-path {
  stroke-width: 1;
  animation: fil 1s linear;
  fill: #944a00;
}

@keyframes fil {
  from {
    fill: transparent;
  }
  to {
    fill: #944a00;
  }

}


.name-path > path {
  stroke-dasharray: 2000;
  animation: dash 5s linear infinite alternate;
  stroke: goldenrod;
  stroke-linecap: round;
}

@keyframes dash {
  from {
    stroke-dashoffset: 1500;
    /* stroke: slategrey; */
  }
  to {
    stroke-dashoffset: 0;
    /* stroke: transparent; */
  }

}